<script>
import '../../utilities/fontawesome'
export default {
  name: 'CsIcon',
  props: {
    icon: {
      type: [String, Object, Array],
      default: null,
      required: true
    },
    transform: {
      type: [String, Object, Array],
      default: null,
      required: false
    },
    size: {
      type: [String, Number],
      default: null,
      required: false
    },
    rotation: {
      type: [String, Number],
      default: null,
      required: false
    },
    flip: {
      type: String,
      default: null,
      required: false
    },
    spin: {
      type: Boolean,
      default: false,
      required: false
    },
    pulse: {
      type: Boolean,
      default: false,
      required: false
    },
    options: {
      type: [String, Object],
      default: null,
      required: false
    }
  },
  data () {
    return {
      map: {
        alert: 'exclamation-square',
        warning: 'exclamation-square',
        danger: 'times-hexagon',
        back: 'arrow-circle-left',
        clear: 'eraser',
        view: 'eye',
        'admin-user': 'user-secret',
        'program-user': 'user-shield',
        'site-user': 'user-cog',
        email: 'envelope',
        'send-email': 'paper-plane',
        courses: 'chalkboard-teacher',
        sessions: 'calendar-alt',
        schedule: 'calendar',
        registration: 'file-invoice-dollar',
        registrations: 'box-usd',
        login: 'sign-in',
        logout: 'sign-out',
        gap: 'indent',
        lunch: 'utensils',
        'food-allergy': 'utensils-alt',
        allergy: 'allergies',
        medical: 'notes-medical',
        signin: 'sign-in',
        signout: 'sign-out',
        profile: 'user-circle',
        reject: 'ban',
        reload: 'sync-alt',
        accept: 'badge-check',
        validated: 'check-circle',
        closed: 'times-hexagon',
        close: 'times-hexagon',
        reset: 'sync',
        save: 'inbox-in',
        cancel: 'ban',
        students: 'user-friends',
        'add-student': 'user-plus',
        student: 'book-reader',
        schedules: 'calendar',
        accounts: 'list',
        signature: 'pen',
        import: 'upload',
        export: 'download',
        merge: 'code-merge',
        pdf: 'file-pdf',
        excel: 'file-excel',
        csv: 'file-spreadsheet',
        'add-file': 'file-plus',
        'delete-file': 'file-minus',
        enrichment: 'school',
        care: 'hand-holding-seedling',
        'new-care': 'hand-holding-seedling',
        'kinder-plus': 'chalkboard-teacher',
        kinderplus: 'chalkboard-teacher',
        new_care: 'hand-holding-seedling',
        kinder_plus: 'chalkboard-teacher',
        vvelc: 'seedling',
        outdoor: 'route',
        summer: 'sun',
        'summer-hs': 'house-day',
        summer_hs: 'house-day',
        'summer-vvp': 'hand-heart',
        summer_vvp: 'hand-heart',

        account: 'list',
        enroll: 'user-plus',
        unenroll: 'user-minus',
        'information-card': 'ballot',
        'edit-information-card': 'ballot-check',
        'complete-information-card': 'ballot-check',
        'information-cards': 'box-ballot',
        pay: 'credit-card-front',
        check: 'money-check',
        refund: 'undo',
        'manual-payment': 'keyboard',
        payments: 'hands-usd',
        discounts: 'handshake',
        select: 'file-check',
        waitlist: 'business-time',
        waitlists: 'user-clock',
        member: 'user',
        members: 'users',
        'add-member': 'user-plus',
        school: 'school',
        schools: 'school',
        teacher: 'chalkboard-teacher',
        teachers: 'users-class',
        room: 'users-class',
        drop: 'eraser',
        delete: 'eraser',
        fee: 'file-invoice-dollar',
        'start-time': 'hourglass-start',
        'end-time': 'hourglass-end',
        'full-time': 'hourglass-half',
        'add-course': 'layer-plus',
        'add-calendar': 'calendar-plus',
        programs: 'tv-retro',
        'block-group': 'object-group',
        'course-block': 'object-group',
        'course-bulk-group': 'object-group',
        waivers: 'file-signature',
        'waiver-text': 'file-contract',
        cart: 'shopping-cart',
        'add-cart': 'cart-plus',
        'drop-cart': 'cart-arrow-down',
        up: 'chevron-up',
        down: 'chevron-down',
        right: 'chevron-right',
        left: 'chevron-left'
      }
    }
  },
  render (h) {
    return h('font-awesome-icon', {
      props: this.customProps
    })
  },
  computed: {
    customProps () {
      if (this.options != null) {
        return this.options
      }
      const props = {
        icon: this.mappedIcon
      }
      if (this.size != null) {
        props.size = this.size
      }
      if (this.rotation != null) {
        props.rotation = this.rotation
      }
      if (this.flip != null) {
        props.flip = this.flip
      }
      if (this.spin != null) {
        props.spin = this.spin
      }
      if (this.pulse != null) {
        props.pulse = this.pulse
      }
      if (this.transform != null) {
        props.transform = this.transform
      }
      props.class = 'm-1'
      return props
    },
    mappedIcon () {
      if (typeof this.icon === 'string') {
        const mapped = this.map[this.icon]
        if (typeof mapped === 'undefined') {
          return this.icon
        } else {
          return mapped
        }
      } else {
        const parts = Object.values(this.icon)
        const maps = this.map
        return parts.map(function (item) {
          const map = maps[item]
          if (typeof map === 'undefined') {
            return item
          } else {
            return map
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
